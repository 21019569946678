import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../../templates/RAC'
import DynamicNotFoundPage from '../../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/ja/structured-finance/*" />
    <RAC path="/zh-cn/structured-finance/*" />
    <RAC path="/zh-tw/structured-finance/*" />
    <RAC path="/pl/structured-finance/*" />
    <RAC path="/ru/structured-finance/*" />
    <RAC path="/it/structured-finance/*" />
    <RAC path="/de/structured-finance/*" />
    <RAC path="/es/structured-finance/*" />
    <RAC path="/fr/structured-finance/*" />
    <RAC path="/ar/structured-finance/*" />
    <RAC path="/id/structured-finance/*" />
    <RAC path="/pt/structured-finance/*" />
    <RAC path="/ko/structured-finance/*" />
    <DynamicNotFoundPage default />
  </Router>
)
